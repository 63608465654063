<template>
  <div>
    <div class="card-toolbar mb-5">

      <button v-if="$can('departments.create')" @click="showModal" class="btn btn-primary font-weight-bolder">
        <v-icon>mdi-plus</v-icon>
        {{ $t('departments.new_department') }}
      </button>
      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row">
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="f_name">{{ $t('departments.name') }}</label>
              <input v-model="filters.name" type="text" id="f_name" class="form-control">
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="f_status">
                {{ $t('status') }}
              </label>
              <select name="" id="f_status" v-model="filters.status" type="text" class="custom-select">
                <option value="">{{ $t('all') }}</option>
                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>
              </select>
            </div>
            <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
              <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"><i class="fas fa-search"></i>
                {{ $t('search') }}
              </b-button>
              <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i>
                {{ $t('reset_search') }}
              </b-button>

            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-body">

        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

          <template slot="status" slot-scope="props">

            <b-form-checkbox   v-if="$can('departments.change_status')" size="lg"
                             @change="changeStatus(props.row.id, props.row.status)" v-model="props.row.status"
                             :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>

            <b-form-checkbox   v-else  v-model="props.row.status"
                             :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
          </template>
          <template slot="actions" slot-scope="props">

            <v-icon small v-if="$can('departments.update')" class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')"
                    @click="showModal(props.row)">mdi-pencil
            </v-icon>

            <v-icon small v-if="$can('departments.delete')" class="mr-2 text-danger" v-b-tooltip.hover :title="$t('delete')"
                     @click="deleteItem(props.row)">mdi-delete
            </v-icon>
          </template>
        </v-server-table>


        <!--end: Datatable-->
      </div>
    </div>
    <!--end::User-->

    <b-modal ref="modal" hide-footer :title="$t('departments.departments')">
      <div class="row">
        <div class="col-lg-6 mt-5">
          <label for="name">{{ $t('departments.name') }}<span class="text-danger">*</span></label>
          <input v-model="data.name" type="text" class="form-control" id="name" :placeholder="$t('departments.name')">
          <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.name[0] }}
                </span>
        </div>
        <div class="col-lg-6 mt-5">
          <label for="the_main">{{ $t('departments.manager') }}<span class="text-danger">*</span></label>
          <div class="input-group">
            <multiselect
                v-model="manager"
                :placeholder="$t('departments.manager')"
                label="name"
                track-by="id"
                :options="users"
                :multiple="false"
                :taggable="false"
                :show-labels="false"
                :show-no-options="false"
                :show-no-results="false"
                @input="setManager"
                @search-change="getUsers($event)">
            </multiselect>
            <div class="input-group-prepend">
              <a class="btn btn-primary" href="/users/users/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
            </div>
          </div>
          <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters')+' '+$t('departments.manager') }}.</span>
          <span v-if="validation && validation.parent_id" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.parent_id[0] }}
                </span>
        </div>
        <div class="col-lg-6 mt-5">
          <label for="key">{{ $t('departments.no_employee') }}</label>
          <input v-model="data.no_employee" type="text" class="form-control" id="key"
                 :placeholder="$t('departments.no_employee')">
          <span v-if="validation && validation.no_employee" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.no_employee[0] }}
                </span>
        </div>
        <div class="col-lg-6 mt-5">
          <b-form-checkbox size="lg" v-model="data.status" name="check-button" switch>{{ $t('status') }}
          </b-form-checkbox>
        </div>
        <div class="col-lg-12 mt-5">
          <label for="description">{{ $t('departments.description') }}</label>
          <textarea v-model="data.description" class="form-control" id="description"
                    :placeholder="$t('departments.description')"></textarea>
          <span v-if="validation && validation.description" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.description[0] }}
                </span>
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <b-button class="mt-2 mr-1" variant="secondary" @click="hideModal">{{ $t('close') }}</b-button>
        <b-button class="mt-2 mr-1" variant="primary" @click="save">{{ $t('save') }}</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "index",
  components: {},
  data() {
    return {
      mainRoute: 'employee/departments',
      subMainRoute: 'employee/department',
      mainRouteDependency: 'base/dependency',


      showAdvancedSearch: false,
      filters: {
        name: '',
        status: '',
      },
      status_list: [
        {id: 1, text: this.$t('active')},
        {id: 0, text: this.$t('inactive')},
      ],
      columns: ['name', 'manager_name', 'no_employee', 'status', 'actions'],

      idEditing: null,
      isEditing: false,
      data: {
        id: null,
        name: null,
        manager_id: null,
        no_employee: null,
        description: null,
        status: true,
      },

      disabledButton: false,
      validation: [],
      dataList: [],
      manager: null,
      users: [],
    }
  },
  computed: {
    json_fields: function () {
      let fields = {};
      fields[this.$t('departments.name')] = 'name';
      fields[this.$t('departments.manager')] = 'manager_name';
      fields[this.$t('departments.no_employee')] = 'no_employee';
      fields[this.$t('status')] = 'status';
      return fields;
    },
    fileName: function () {
      return this.$t('departments.departments');
    },
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          limit: that.$t('records'),
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          name: that.$t('departments.name'),
          manager_name: that.$t('departments.manager'),
          no_employee: that.$t('departments.no_employee'),
          status: that.$t('status'),
          actions: that.$t('actions'),

        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'created_at'},

        alwaysShowPerPageSelect: true,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(that.mainRoute, {..._params});

        },
        responseAdapter(resp) {
          that.dataList = resp.data.data.data;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  mounted() {
    // let that = this;
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.departments")}]);
  },
  methods: {
    getFetch() {
      this.$refs.table.refresh();
    },
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.name = null;
      this.filters.status = null;

      this.$refs.table.refresh();
    },
    getData(id) {
      ApiService.get(`${this.mainRoute}/${id}`).then((response) => {
        this.isEditing = true;
        this.data.id = response.data.data.id;
        this.data.name = response.data.data.name;
        this.data.manager_id = response.data.data.manager_id;
        this.data.no_employee = response.data.data.no_employee;
        this.data.description = response.data.data.description;
        this.data.status = response.data.data.status;
        this.manager = response.data.data.manager;
      });
    },
    actionDelete(item) {
      ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((errors) => {
        this.$errorAlert(errors);
      })
    },
    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },

    showModal(data) {
      if (!data.id) {
        this.reset();
        this.$refs['modal'].show()
      } else {
        this.isEditing = true;
        this.id = data.id;
        this.getData(data.id);
        this.$refs['modal'].show();
      }
    },
    reset() {
      this.data = {
        id: null,
        name: null,
        manager_id: null,
        no_employee: null,
        description: null,
        status: false,
      };
      this.validation = [];
      this.isEditing = false;
      this.manager = null;
    },
    hideModal() {
      this.$refs['modal'].hide();
      this.afterSave();
    },
    toggleModal() {
      this.$refs['modal'].toggle('#toggle-btn');
    },
    save() {
      if (this.isEditing) {
        this.saveEdit();
      } else {
        this.saveCreate();
      }
    },

    saveCreate() {
      ApiService.post(this.mainRoute, this.data).then((response) => {
        this.$successAlert(response.data.message);
        this.afterSave();
        this.hideModal();
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },
    saveEdit() {
      ApiService.put(`${this.mainRoute}/${this.id}`, this.data).then((response) => {
        this.$successAlert(response.data.message);
        this.afterSave();
        this.hideModal();
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },
    afterSave() {
      this.getFetch();
      this.data = {
        id: null,
        name: null,
        manager_id: null,
        no_employee: null,
        description: null,
        status: true,
      };
      this.idEditing = null;
      this.manager = null;

    },
    changeStatus(id, status) {
      ApiService.patch(this.subMainRoute + '/change-status/' + id, {
        status: (status ? 1 : 0),
      }).then(response => {
        this.$refs.table.refresh();
        this.$successAlert(response.data.message);
      }).catch(error => {
        this.$errorAlert(error);
      });
    },
    getUsers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/users", {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
    },
    setManager(event) {
      if (event)
        this.data.manager_id = event.id;
      else
        this.data.manager_id = null;
    },
  },
};
</script>
